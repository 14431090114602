import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import loginImg from "../../../assets/Dexlabz/hero-bg.svg";
import CustomButton from "../Shared/CustomButton/CustomButton";
import OTPInput from "react-otp-input";
import { AiFillCloseCircle } from "react-icons/ai";
import { connect } from "react-redux";
import { LoginThunk, clearError, setError } from "../../../redux/LoginSlice";
import { AuthContext } from "../../../providers/AuthProvider";
import { WebConstant } from "../../../api/ApiConstants";
import ForgotPassword from "./ForgotPassword";

function Login({
  setShowLoginPopup,
  setShowJoinFreePopup,
  userInfo,
  loginUser,
  isloading,
  hasError,
  setValidationError,
  errorMessage,
  clearError,
  className,
}) {
  const [showPasswordField, setShowPasswordField] = useState(true);
  const [isOtpRequested, setIsOtpRequested] = useState(false);
  const [showOtpBox, setShowOtpBox] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showForgotPasswordPopup, setShowForgotPasswordPopup] = useState(false);

  const { login: setLoginToken } = useContext(AuthContext);

  if (userInfo?.serverResponse?.accessToken) {
    setLoginToken(userInfo?.serverResponse?.accessToken);
  }

  function ValidateEmail(mail) {
    if (
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  }

  const handleLogin = async () => {
    if (email === undefined || email === null || email.length === 0) {
      setValidationError("Email is required");

      return;
    }

    if (!ValidateEmail(email)) {
      setValidationError("Entered email is incorrect !");

      return;
    }

    if (password === undefined || password === null || password.length === 0) {
      setValidationError("Password is required");

      return;
    }

    loginUser({ email, password });
  };

  return (
    <div>
      <div className="bg-[rgba(128,128,128,.8)] w-full h-screen fixed top-0 left-0 z-50 rounded-md">
        <div
          className={`bg-white h-[100vh] w-full   lg:w-full tablet:w-[50%] 2sm:w-[60%] absolute left-0 right-0 mx-auto top-0 bottom-0 my-auto   flex items-center justify-between flex-col md:flex-row  md:px-0 ${className}`}
        >
          <div className="hidden lg:block md:w-[60%] ">
            <div className="  bg-blue-100 p-5 rounded-s-md h-[18rem] md:h-[100vh] flex flex-col-reverse items-center md:block tablet:hidden relative">
              {/* <div className="text-3xl ">
              <AiFillCloseCircle
                className="cursor-pointer"
                onClick={() => setShowLoginPopup(false)}
              />
            </div> */}

              <div className=" px-5 text-gray-500 pt-32 ">
                <p className="font-bold text-gray-600 text-2xl">
                  Over 10 crore learners trust us for their preparation
                </p>
                <ul className="list-disc ps-5 mt-5 text-lg">
                  <li className="my-5">
                    Learn from 2,000+ India’s top educators
                  </li>
                  <li className="my-5">
                    Access 3,00,000+ live classes, test series, courses and more
                    for free
                  </li>
                </ul>
              </div>

              <img
                src={loginImg}
                className="scale-90 w-[40rem] absolute bottom-10 left-0 right-0 mx-auto "
                alt=""
              />
            </div>
          </div>

          <div className="w-full md:w-[40%]  tablet:w-full px-5 py-10 md:p-10 lg:px-20 flex justify-start flex-col items-start font-poppins">
            {/* <div className="text-3xl ms-auto md:hidden tablet:block">
              <AiFillCloseCircle
                className="cursor-pointer"
                onClick={() => setShowLoginPopup(false)}
              />
            </div> */}

            <p className=" text-3xl tablet:text-3xl  lg:text-center lg:text-4xl font-bold font-poppins ">
              Login to Classroom
            </p>
            <p className=" text-lg tablet:text-xl tablet:ms-0 tablet:ps-0  text-center 2xl:text-lg mt-2 ">
              or{" "}
              <Link
                to=""
                className="text-red-500 font-bold"
                onClick={() => {
                  setShowLoginPopup(false);
                  setShowJoinFreePopup(true);
                }}
              >
                create a free account
              </Link>
            </p>

            <div className="my-5 w-full flex justify-center flex-col ">
              {hasError && (
                <p className="bg-red-200 text-red-800 p-2 my-3 rounded">
                  {errorMessage}
                </p>
              )}
              {!showForgotPasswordPopup && (
                <div className="flex items-start flex-col w-full ">
                  <div className="sm:col-span-3 w-full">
                    <div className="my-2">
                      <input
                        type="email"
                        value={email}
                        className="block w-full rounded-md border-0 py-4 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-100 sm:leading-6 text-lg md:text-xl"
                        placeholder="Email address"
                        onChange={(e) => {
                          setEmail(e.target.value);
                          clearError();
                        }}
                      />
                    </div>
                  </div>

                  {!showPasswordField && (
                    <p className="my-2 text-sm lg:text-base text-gray-600 ">
                      We’ll send an OTP for verification
                    </p>
                  )}

                  {showPasswordField && (
                    <div className="sm:col-span-3 w-full">
                      <div className="my-2">
                        <input
                          type="password"
                          value={password}
                          className="block w-full rounded-md border-0 py-4 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-100 sm:leading-6 text-lg md:text-xl"
                          placeholder="Password"
                          onChange={(e) => {
                            setPassword(e.target.value);
                            clearError();
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
              {showForgotPasswordPopup && <ForgotPassword />}
              {showOtpBox && (
                <div className="flex justify-center my-5 ">
                  <OTPInput
                    // value={otp}
                    // onChange={setOtp}
                    inputStyle={{ width: "2.5rem" }}
                    numInputs={6}
                    renderSeparator={<span></span>}
                    renderInput={(props) => (
                      <input
                        {...props}
                        className="border border-black rounded-md px-2 mx-1  h-[3rem] "
                      />
                    )}
                  />
                </div>
              )}
              {!isOtpRequested && !showPasswordField && (
                <CustomButton
                  lable={"Request OTP"}
                  className={`w-full !mx-0 my-4 !text-lg !py-4 bg-gray-700 text-white border-transparent hover:bg-gray-800 `}
                  // onClick={() => handleGenerateOtp()}
                />
              )}
              {isOtpRequested && !showPasswordField && (
                <CustomButton
                  lable={"Verify OTP"}
                  className={`w-full !mx-0 my-4 !text-lg !py-4 bg-gray-700 text-white border-transparent hover:bg-gray-800 `}
                  // onClick={() => handleVerifyOtp()}
                />
              )}
              {showPasswordField && !showForgotPasswordPopup && (
                <CustomButton
                  lable={"Login"}
                  className={`w-full !mx-0 my-4 !text-lg !py-4 bg-gray-700 text-white border-transparent hover:bg-gray-800 `}
                  onClick={handleLogin}
                />
              )}
              {!showPasswordField && (
                <p
                  className=" text-sm   text-center 2xl:text-base mt-2 font-bold  underline decoration-dashed underline-offset-8 tracking-widest "
                  onClick={() => setShowPasswordField(true)}
                >
                  <Link to="" className="text-bold ">
                    Continue with email & password
                  </Link>
                </p>
              )}
              {/* {showPasswordField && (
                <p
                  className=" text-sm   text-center 2xl:text-base mt-2 font-bold  underline decoration-dashed underline-offset-8 tracking-widest "
                  onClick={() => setShowPasswordField(false)}
                >
                  <Link to="" className="text-bold ">
                    Continue with email & OTP
                  </Link>
                </p>
              )} */}
              {!showForgotPasswordPopup && (
                <p
                  className="text-center text-sm cursor-pointer underline text-blue-700 font-semibold"
                  onClick={() => setShowForgotPasswordPopup(true)}
                >
                  Forgot Password
                </p>
              )}
              {showForgotPasswordPopup && (
                <p
                  className="text-center text-sm cursor-pointer underline text-blue-700 font-semibold"
                  onClick={() => setShowForgotPasswordPopup(false)}
                >
                  Back to login
                </p>
              )}
              <p className=" text-xsm   text-center 2xl:text-sm mt-2  absolute bottom-5  self-center w-full ">
                Having trouble?
                <br />
                Write us on{" "}
                <Link to="" className="text-bold font-normal">
                  help@{WebConstant?.WEB_NAME}.com
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.loginReducer.userInfo,
    hasError: state.loginReducer.error,
    isloading: state.loginReducer.loading,
    errorMessage: state.loginReducer.errMsg,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: (body) => {
      dispatch(LoginThunk(body));
    },
    setValidationError: (err) => {
      dispatch(setError(err));
    },
    clearError: () => {
      dispatch(clearError());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
