import React, { useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useGetAllSectionsByCourseUidQuery } from "../../../api/ReduxToolkit";
import { useNavigate } from "react-router-dom";
import processing from "../../../assets/Dexlabz/mentinence.json";
import BrandLogo from "../Shared/BrandLogo/BrandLogo";
import Lottie from "lottie-react";
import SubStoreImageCard from "../CreateThumbnail/SubStoreImageCard";

function SectionPopup({ rightPopup, setShowSectionPopup, courseData }) {
  const navigate = useNavigate();

  const { data: allSections } = useGetAllSectionsByCourseUidQuery(
    courseData?.courseUid
  );

  useEffect(() => {
    console.log("section data ", allSections?.sections);
  }, [useGetAllSectionsByCourseUidQuery]);

  const handleCardClick = (sectionData) => {
    // console.log("sec", sectionData);
    navigate("/player", {
      state: {
        courseUid: courseData?.courseUid,
        courseData: courseData,
        sectionName: sectionData?.sectionName,
        sectionUid: sectionData?.sectionUid,
        sectionId: sectionData?._id,
        educatorName: courseData?.educatorInfo?.[0]?.educatorName,
      },
    });
  };

  return (
    <div className="bg-[rgba(128,128,128,.6)] w-full h-screen fixed top-0 left-0 z-50 ">
      <div
        className={`w-[95%] lg:w-[40%] 2xl:w-[35%] 3xl:w-[30%] 6xl:w-[20%] tablet:w-[70%] 2sm:w-[50%] bg-white   min-h-full md:h-screen  absolute right-0 top-0  transition-all duration-700  ${rightPopup}`}
      >
        <AiOutlineClose
          className="absolute text-3xl right-3 top-3 md:right-10 md:top-5 cursor-pointer  p-1.5 rounded-full bg-gray-400 text-white"
          onClick={() => setShowSectionPopup(false)}
        />

        <div className="w-full px-5 flex flex-col items-center justify-center">
          <p className="my-10 font-medium text-xl text-[#313639] self-start">
            All Sections
          </p>
          {allSections?.sections && allSections?.sections.length > 0 ? (
            <div
              className="flex flex-col overflow-y-auto h-[85vh] no-scrollbar"
              id="scrollable"
            >
              {allSections?.sections?.map((sectionData, index) => (
                <div
                  className="flex justify-between items-start shadow rounded-lg  min-h-[7rem] w-full md:w-[30rem] 2sm:w-full lg:w-full xl:w-[30rem]  my-3 cursor-pointer "
                  onClick={() => handleCardClick(sectionData)}
                  key={index}
                >
                  <div className="w-[50%] md:w-[35%] h-full ">
                    {/* {sectionData?.Thumbnail ? (
                      <img
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTj_0Xr9NGJgOcmUn3SKp_5iZg4vHWrOU5I_w&usqp=CAU"
                        alt=""
                        className="w-full h-[5.5rem]"
                      />
                    ) : (
                      <div className="bg-neutral-200 flex justify-center items-center h-full rounded-md">
                        <BrandLogo className={`w-[4rem] h-[4rem]`} />
                      </div>
                    )} */}
                    <SubStoreImageCard
                      imageUrl={require("../../../assets/ThumbnailsImages/style17.png")}
                      backgroundColor="bg-gray-100"
                      boxHight="h-[7rem]"
                      // heading={data?.courseName}
                      // config={imageStyle?.STYLE_1}
                      // language={data?.language?.[0]?.languageName}
                    />
                  </div>
                  <div className="w-[50%] md:w-[65%] px-5 py-2">
                    <p className="font-medium text-[#313639] text-xs md:text-base line-clamp-1">
                      {sectionData?.sectionName}
                    </p>
                    <p className="text-xs line-clamp-3 md:line-clamp-4 text-gray-400">
                      {sectionData?.sectionDescription}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className=" w-full flex flex-col justify-center items-center my-auto h-[80vh] ">
              <Lottie
                animationData={processing}
                loop={true}
                className="w-[150px] h-[150px] md:w-[200px] md:h-[200px] "
              />
              <div className=" text-sm font-medium text-[#313639]">
                Work is going on under-processing
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SectionPopup;
