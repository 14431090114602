import React, { useState } from "react";
import CustomButton from "../Shared/CustomButton/CustomButton";
import { enqueueSnackbar, SnackbarProvider } from "notistack";
import { useSendResetPasswordLinkMutation } from "../../../api/ReduxToolkit";
import PdfLoader from "../UcomPlayer/PdfLoader";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPasswordField, setShowPasswordField] = useState(false);

  const [sendResetPasswordLink, { isLoading: sendResetPasswordLinkIsLoading }] =
    useSendResetPasswordLinkMutation();

  function ValidateEmail(mail) {
    if (
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  }
  const handleResetPassword = async () => {
    if (!email || email.length === 0) {
      enqueueSnackbar("Email is required", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }

    if (!ValidateEmail(email)) {
      enqueueSnackbar("Entered email is incorrect!", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }

    try {
      const response = await sendResetPasswordLink({ body: { email } });

      if (response) {
        console.log(response?.data?.message);
        enqueueSnackbar(response?.data?.message, {
          autoHideDuration: 3000,
          variant: "success",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
      }
    } catch (error) {
      // enqueueSnackbar(
      //   error?.response?.data?.message || "Failed to send reset password link.",
      //   {
      //     autoHideDuration: 3000,
      //     variant: "error",
      //     anchorOrigin: { horizontal: "right", vertical: "top" },
      //   }
      // );
      console.log("Mutation Error", error);
    }
  };

  return (
    <SnackbarProvider>
      <div className="flex items-start flex-col w-full ">
        {sendResetPasswordLinkIsLoading && <PdfLoader />}
        <div className="sm:col-span-3 w-full">
          <div className="my-2">
            <input
              type="email"
              value={email}
              className="block w-full rounded-md border-0 py-4 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-100 sm:leading-6 text-lg md:text-xl"
              placeholder="Email address"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
        </div>

        {showPasswordField && (
          <div className="sm:col-span-3 w-full">
            <div className="my-2">
              <input
                type="password"
                value={password}
                className="block w-full rounded-md border-0 py-4 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-100 sm:leading-6 text-lg md:text-xl"
                placeholder="Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </div>
          </div>
        )}

        <CustomButton
          lable={"Send Reset Password Link"}
          className={`w-full !mx-0 my-4 !text-lg !py-4 bg-gray-700 text-white border-transparent hover:bg-gray-800 `}
          onClick={() => handleResetPassword()}
        />
      </div>
    </SnackbarProvider>
  );
}

export default ForgotPassword;
