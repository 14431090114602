import { Route, Routes } from "react-router-dom";

import UnacademyTheme from "../pages/UnacademyTheme";
import DashBoard from "../pages/ UnacademyPages/dashboard/DashBoard";
import SelectGole from "../pages/ UnacademyPages/AfterLoginPages/SelectGoal/SelectGoal";
import UcomPlayer from "../pages/ UnacademyPages/UcomPlayer/UcomPlayer";
import { AuthContext } from "../providers/AuthProvider";
import { useContext } from "react";
import Unauthorise from "../pages/ UnacademyPages/WildCardRouteComponent/Unauthorise";
import SuccessStories from "../pages/ UnacademyPages/UnacademyComponents/SuccessStories";
import Profile from "../pages/ UnacademyPages/Profile/Profile";
import UserSettings from "../pages/ UnacademyPages/UserSettings/UserSettings";
import SubscriptionPlan from "../pages/ UnacademyPages/UnacademyComponents/SubscriptionPlan";
import UnacademeyStore from "../pages/ UnacademyPages/UnacademyComponents/UnacademeyStore";
import AfterLoginUnauthorise from "../pages/ UnacademyPages/WildCardRouteComponent/AfterLoginUnauthorise";
import SubStore from "../pages/ UnacademyPages/SubStore/SubStore";
import PaymentSuccess from "../pages/ UnacademyPages/SubStore/PaymentSuccess";
import PaymentRejected from "../pages/ UnacademyPages/SubStore/PaymentRejected";
import PaymentPending from "../pages/ UnacademyPages/SubStore/PaymentPending";
import Refer from "../pages/ UnacademyPages/Refer/Refer";
import Tnc from "../pages/ UnacademyPages/TermsConditions/Tnc";
import Faq from "../pages/ UnacademyPages/FAQ/Faq";
import Classroom from "../pages/ UnacademyPages/Classroom/Classroom";
import CustomCarousel from "../pages/ UnacademyPages/Shared/CustomCarousel/CustomCarousel";
import OnlineTest from "../pages/ UnacademyPages/OnlineTest/OnlineTest";
import PrivacyPolicy from "../pages/ UnacademyPages/PrivacyPolicy/PrivacyPolicy";
import RefundPolicy from "../pages/ UnacademyPages/RefundPolicy/RefundPolicy";
import ResetPassword from "../pages/ UnacademyPages/Login/ResetPassword";

export const StudentRouter = () => {
  return <ProtectRoutes />;
};

const ProtectRoutes = () => {
  const { accessToken } = useContext(AuthContext);

  return accessToken !== null && accessToken !== undefined ? (
    <Routes>
      <Route path="*" element={<AfterLoginUnauthorise />} />
      <Route path="/" element={<DashBoard />} />
      <Route path="/goal" element={<SelectGole />} />
      <Route path="/player" element={<UcomPlayer />} />
      <Route path="/user-profile" element={<Profile />} />
      <Route path="/user-settings" element={<UserSettings />} />
      <Route path="/success-stories" element={<SuccessStories />} />
      <Route path="/plan" element={<SubscriptionPlan />} />
      <Route path="/store" element={<UnacademeyStore />} />
      <Route path="/sub-store" element={<SubStore />} />
      <Route path="/payment-success" element={<PaymentSuccess />} />
      <Route path="/payment-failed" element={<PaymentRejected />} />
      <Route path="/payment-pending" element={<PaymentPending />} />
      <Route path="/refer" element={<Refer />} />
      <Route path="/terms-conditions" element={<Tnc />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/refund-policy" element={<RefundPolicy />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/classroom" element={<Classroom />} />
      <Route path="/carousel" element={<CustomCarousel />} />
      <Route path="/test" element={<OnlineTest />} />
    </Routes>
  ) : (
    <Routes>
      <Route path="/" element={<UnacademyTheme />} />
      <Route path="*" element={<Unauthorise />} />
      <Route path="/password-reset" element={<ResetPassword />} />
    </Routes>
  );
};
