import React, { useEffect, useState } from "react";
import CustomButton from "../Shared/CustomButton/CustomButton";
import WebLogo from "../../WebLogo/WebLogo";
import { useLocation, useNavigate } from "react-router-dom";
import { useResetPasswordMutation } from "../../../api/ReduxToolkit";
import { enqueueSnackbar, SnackbarProvider } from "notistack";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import icons
import PdfLoader from "../UcomPlayer/PdfLoader";

function ResetPassword() {
  const [email, setEmail] = useState("kkgkrishna112@gmail.com");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState(""); // State to store the token
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const location = useLocation(); // Get the current location object
  const navigate = useNavigate();
  const [resetPassword, { isLoading: resetPasswordIsLoading }] =
    useResetPasswordMutation();

  // Extract "id" parameter from the URL and set it as the token
  useEffect(() => {
    const getIdFromURL = (search) => {
      const params = new URLSearchParams(search); // Extract query parameters
      return params.get("id"); // Get the value of the "id" parameter
    };

    const idFromURL = getIdFromURL(location.search); // Pass `location.search` to the function
    if (idFromURL) {
      setToken(idFromURL); // Set the token state with the extracted id
    }
  }, [location.search]); // Run when `location.search` changes

  const handleResetPassword = async () => {
    if (!password || password.length === 0) {
      enqueueSnackbar("New password is required", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "top" },
      });
      return;
    }

    try {
      const response = await resetPassword({
        body: {
          token: token,
          newPassword: password,
        },
      });

      if (response?.data) {
        enqueueSnackbar(response?.data?.message, {
          autoHideDuration: 3000,
          variant: "success",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
        setTimeout(() => {
          navigate("/"); // Navigate to the root ("/") after 2 seconds
        }, 3000); // 3000ms = 3 seconds
      }
      if (response?.error) {
        enqueueSnackbar(response?.error?.data?.message, {
          autoHideDuration: 3000,
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
      }
    } catch (error) {
      console.log("Mutation Error", error);
    }
  };

  return (
    <SnackbarProvider>
      {resetPasswordIsLoading && <PdfLoader />}
      <div className="h-screen w-screen flex items-center justify-center bg-gray-800/80">
        <div className="flex items-start flex-col w-[90%] md:w-[25rem] bg-white p-5 rounded-2xl shadow-no-border-shadow">
          <WebLogo className={`w-[15rem] -translate-x-3`} />
          <p className="text-2xl font-bold font-poppins mb-5 text-center">
            Reset Password
          </p>
          {/* <div className="sm:col-span-3 w-full">
            <div className="my-2">
              <input
                type="email"
                value={email}
                className="block w-full rounded-md border-0 py-3 px-3 text-gray-600 shadow-sm  ring-gray-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-100 sm:leading-6  bg-gray-300 cursor-not-allowed"
                placeholder="Email address"
                onChange={(e) => setEmail(e.target.value)}
                disabled
              />
            </div>
          </div> */}

          <div className="sm:col-span-3 w-full relative">
            <div className="my-2">
              <input
                type={showPassword ? "text" : "password"} // Toggle between password and text
                value={password}
                className="block w-full rounded-md border-0 py-3 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-100 sm:leading-6   "
                placeholder="Enter New Password"
                onChange={(e) => setPassword(e.target.value)}
              />
              {/* Eye icon to toggle password visibility */}
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500"
              >
                {showPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}{" "}
                {/* Show/hide icon */}
              </button>
            </div>
          </div>

          <CustomButton
            lable={"Save"}
            className={`w-full !mx-0 my-4 !text-lg !py-3 bg-gray-700 text-white border-transparent hover:bg-gray-800`}
            onClick={handleResetPassword} // Call the handleResetPassword function
          />
        </div>
      </div>
    </SnackbarProvider>
  );
}

export default ResetPassword;
